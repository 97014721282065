import type { HTMLAttributes } from 'react';

import { cn } from '~/libs/utils';

function Skeleton({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn('w-full animate-pulse rounded-md bg-muted', className)} data-testid='skeleton' {...props} />
  );
}

export { Skeleton };
